import React from 'react'
import Infosection3 from '../InfoSection/Infosection3'
import { PerObjOne} from './Data'
import Footer from '../Footer2'
import {Helmet} from 'react-helmet'


function Partner() { 
    return (
        <>
                <Helmet>
            <title>Partner with us</title>
            <meta
      name="description"
      content="The Voice of The Youth with Disabilities (VYD) is a dynamic Community Based Organization in Uganda, passionately committed to empowering young people with disabilities. VYD's primary mission is to enhance capacity and foster economic self-sufficiency by nurturing entrepreneurship skills among the youth with disabilities. This approach aims to reduce their financial dependence on others and promote self-reliance within the community."
    />
    <link rel="apple-touch-icon" href="/images/ywd logo.png" />
    <style type="text/css"></style>

        </Helmet>
            <Infosection3 {...PerObjOne}/>
        </>
    )
}

export default Partner
