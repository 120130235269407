import React, {useEffect} from "react";
import GlobalStyle from './globalStyles' 
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import NavReg from './components/Nav'
//import Footer from './components/Footer'
import Home from './components/pages/Home'
import Aboutus from "./components/pages/About";
import Team from "./components/pages/Team";
import Contact from "./components/Contact";
import Partner from "./components/pages/Partner";
import Plan from "./components/pages/Plan";
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet'
import ScrollToTop from './components/ScrollToTop'
import Footer2 from "./components/Footer2";


function App() {
  useEffect(() => {
    ReactGA.initialize('UA-201141494-1');
    ReactGA.pageview('/App');
    //ReactGA.pageview(window.location.pathname + window.location.search);

  }, [] ) 
  return (
    <>
            <Helmet>
            <title>THE VOICE OF THE YOUTH WITH DISABILITIES</title>
            <meta
      name="description"
      content="The Voice of The Youth with Disabilities (VYD) is a Community Based Organization and it is one of the active groups of youth with disabilities in Uganda, VYD focuses on Capacity building and economic empowerment mainly through developing entrepreneurship skills among the youth with disabilities to trickle down financial over dependency"
    />
    <link rel="apple-touch-icon" href="/images/ywd logo.png" />
    <meta name="keywords" content="youth, disabilities, financial dependency, abilities, covid, entrepreneurship, NGOs, development, CBO, project, Uganda, capacity, organization, technology, " />
    <style type="text/css"></style>
        </Helmet>
    <Router>
    <ScrollToTop />
      <GlobalStyle /> 
     <Navbar/>
     <Switch>
     <Route path ='/strategic-plan'>
         <Plan />
         </Route>
     <Route path ='/partner-with-us'>
         <Partner />
         </Route>
     <Route path ='/contact'>
         <Contact />
         </Route>      
     <Route path ='/team'>
         <Team />
         </Route> 
       <Route path ='/about'>
         <Aboutus />
         </Route> 
         <Route path ='/'>
        <Home />
        </Route>  
    </Switch>
     </Router>
    <Footer2/>

     </>
  );
}

export default App;
