import React from 'react';
//import { BrowserRouter as  Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import icon1 from './images/about.png'
//  import icon2 from '/images/team.png'
//  import icon3 from '../images/plan.png'
//  import icon4 from '../images/partner.png'
import {InfosectionContainer, InfosectionWrapper, InfosectionCard, InfoIcon, InfoH2, InfoP, InfosectionCard2
    } from './infosection.elements'

function Infosection() {
    return (

        <InfosectionContainer>
            <InfosectionWrapper>
            <InfosectionCard>
                         <InfoIcon>
                        <img src="/images/about.png" alt=""/>
                        </InfoIcon>                   
                    <InfoH2><h2>Entrepreneurship Skills</h2></InfoH2>
                    <InfoP><p>VYD equips youth with disabilities with essential entrepreneurship skills to foster economic independence and self-sufficiency.</p></InfoP>
                            </InfosectionCard>
                <InfosectionCard2 >
                    <InfoIcon>
                    <img src="/images/team.png" alt=""/>
                    </InfoIcon>
                    <InfoH2><h2>Education Inclusion</h2></InfoH2>
                    <InfoP><p>We work to ensure equal access to quality education for youth with disabilities, empowering them to reach their academic goals.</p></InfoP>
                </InfosectionCard2>
                <InfosectionCard>
                    <InfoIcon>
                    <img src="/images/plan.png" alt=""/>
                    </InfoIcon>
                    <InfoH2><h2>Advocacy for Rights</h2></InfoH2>
                    <InfoP><p>VYD advocates for the rights and abilities of youth with disabilities, promoting social, political, and economic opportunities.</p></InfoP>
                </InfosectionCard>
                <InfosectionCard2>
                    <InfoIcon>
                    <img src="/images/partner.png" alt=""/>
                    </InfoIcon>
                    <InfoH2><h2>Mental Health Awareness</h2></InfoH2>
                    <InfoP><p>We raise awareness about mental health challenges faced by our members, offering support and mindset coaching.</p></InfoP>
                </InfosectionCard2>

            </InfosectionWrapper>
            
        </InfosectionContainer>

        
    )
}

export default Infosection
