import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCEyNHOM37BSPSaSr6x7OTPv7hqhjPKrTc",
    authDomain: "vyd-contact-db.firebaseapp.com",
    projectId: "vyd-contact-db",
    storageBucket: "vyd-contact-db.appspot.com",
    messagingSenderId: "1094185855968",
    appId: "1:1094185855968:web:41b9b539991089815c1985",
    measurementId: "G-38QPCZHX3W"
    
  });

var db = firebaseApp.firestore();

export { db };
