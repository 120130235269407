import React from 'react';
import './card.style.css';

const Card = props => {
    return (
        <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <div className="card text-center">
            <div className="overflow">
                <img src= {props.imgscr} alt="" className="card-img-top" />

            </div>
            <div className="card-body text-dark">
                <h4 className="card-title"> {props.title} </h4>
                <p  className="card-text text-secondary">{props.desc} <p><strong>Type of disability:</strong>{props.disability}</p> <p> <strong>Profile:</strong>{props.profile} </p> </p>
            </div>

        </div>
        </>
    );
}; 

export default Card;