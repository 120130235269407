import React from 'react'
import {InfoContainer,
InfoWrapper,
InfoRow,
Column1,
TextWrapper,
TopLine,
Heading,
SubTitle,
ImgWrapper,
Column2,
} from './Infosection3.elements'
const Infosection3 = ({imgStart, topLine, headLine, Description, alt, img}) => {
    return (
        <>
          <InfoContainer >
              <InfoWrapper>
                  <InfoRow imgStart={imgStart}>
                      <Column1>
                      <TextWrapper>
                      <TopLine><h4>{topLine}</h4></TopLine>
                      <Heading><h1>{headLine} </h1> </Heading>
                      <SubTitle> 

At VYD, our mission revolves around instilling three essential values in youth with disabilities: self-confidence, a resilient mindset, and a sense of purpose. We achieve this through nurturing disability pride, fostering leadership skills, cultivating entrepreneurship talents, promoting healthy relationships, and encouraging active community engagement. Our ultimate goal is to ensure that young individuals with disabilities excel academically and in their careers.

Our core focus lies in mentorship, career readiness, entrepreneurship, and leadership development. We aspire to create a world where young people with disabilities can live with dignity, embracing their true selves and leading self-determined lives filled with purpose.

To realize this vision, we are committed to enhancing the skills and capabilities of young individuals with disabilities. Moreover, we are dedicated to fostering inclusivity within workplaces, organizations, and communities.

The key to our success lies in partnerships. Join hands with us on this transformative journey towards empowering youth with disabilities and creating a more inclusive, accepting world. Together, we can make a difference. 
 </SubTitle>
                      </TextWrapper>
                      </Column1>
                        <Column2>
                        <ImgWrapper>
                            <img src='/images/partner.jpg'  alt={alt}/> 
                        </ImgWrapper>
                        </Column2>

                  </InfoRow>
              </InfoWrapper>
              </InfoContainer>  
        </>
    )
}

export default Infosection3
