import React, { Component }from 'react'
import Card from './CardUI'

class Cards extends Component {
    render() {
        return (
            <>
            <div className="container-sm justify-content-center">
            <h2 className="header"> MEET OUR EXECUTIVE TEAM </h2>
                <div className="row">
                <div className="col-md-4">
                <Card imgscr='/images/me.jpg' title="KIHEMBO WILBERT" desc="EXECUTIVE DIRECTOR/FOUNDER" disability="Cerebral Palsy" profile="Self-taught Android/web developer, computer science student, founder of KW Software Company & Snow FM Kasese in early startup stage." />
                </div>
                <div className="col-md-4">
                <Card imgscr='/images/Rabson.jpg' title="MASEREKA RABSON" desc="CHAIRPERSON" disability="Physical" profile="Despite his physical disability, Masereka is a resilient businessman operating in Kasese town, specializing in crafting shoes."/>

                </div>
                <div className="col-md-4"> 
                <Card imgscr='' title="MUSOKI MEBO" desc="VICE CHAIRPERSON" disability="Physical" profile="She's a diligent and determined young lady who is committed to her Braille studies, showcasing her strong work ethic and dedication."/>
                </div>
                </div>


                <div className="row">
                <div className="col-md-4">
                <Card  imgscr='/images/leo.jpg' title="MUMBERE LEONARD" desc="GENERAL SECRETARY" disability="Cerebral Palsy" profile="Experienced Special Needs Teacher, adept in diverse disability concerns, proudly parenting a child."/>
                </div>
                <div className="col-md-4">
                <Card imgscr='/images/bridget.jpg' title="BIIRA BRIDGET" desc="TREASURER" disability="Cerebral Palsy" profile="Bridget's fervor for agriculture is rivaled only by her unwavering commitment to education, showcasing her dedication to both fields."/>

                </div>
                <div className="col-md-4"> 
                <Card imgscr='/images/godfrey.jpg' title="KULE GEOFREY" desc="BLIND REPRESENTATIVE" disability="Blind" profile="A Kasese standout, celebrated for mesmerizing piano melodies. A senior six graduate, eagerly anticipating higher pursuits in the world of music. "/>
                </div>
                </div>


                <div className="row">
                <div className="col-md-4">
                <Card imgscr='/images/fath.jpg' title="KABUGHO FAITH" desc=" CEREBRAL PALSY REPRESENTATIVE" disability="Cerebral Palsy" profile="Faith, a friendly senior two student at St. Agnes Vocational School, enjoys building meaningful friendships with peers in her community."/>
                </div>
                <div className="col-md-4">
                <Card imgscr='/images/sadrack.jpg' title="TEMBO SADRACK" desc="PUBLICITY" disability="Cerebral Palsy" profile="An exceptionally bright gentleman who leads disability initiatives at the parish level, actively engaged in community matters pertaining to disabilities.  "/>

                </div>
                <div className="col-md-4"> 
                <Card imgscr='/images/un.jpg' title="" desc=" SPINAL CORD INJURY REPRESENTATIVE" disability="Spinal Cord Injury" profile="A focused Senior Two student at St. Agnes Vocational School, dedicated to realizing his dreams and aspirations with unwavering determination." /> 
                </div>
                <div className="col-md-4">
                <Card imgscr='/images/seguya.jpg' title="MAALI SEGUYA HEZEKIAH" desc="ADVISOR" disability="N/A" profile="Passionate about assisting PWDs, teaches at RAPCD PRIMARY SCHOOL (SNE school in Kasese), and serves as UNAC's Kasese focal person."/>

                </div>
                </div>
               
            </div>
            </>
        );
    }
}

export default Cards;