import React from 'react'
import {InfoSec, 
    ImgWrapper,
    InfoRow, 
    Column1, 
    TextWrapper,
TopLine,
Heading,
SubTitle,
InfosectionContainer, 
InfosectionCard,
InfoH2,
InfoP, 
Column2,
InfoContainer,
InfoWrapper} from './Infosection3.elements'
//import { Container } from '../../globalStyles'
const Infosection2 = () => {
    return (
        <>
         
         <InfoContainer>

                <InfoWrapper>
                <InfoRow>
                <Column1>
                    <TextWrapper>
                        <TopLine></TopLine>
                        <Heading> <h1>ABOUT US</h1></Heading> 
                        <SubTitle>The Voice of The Youth with Disabilities (VYD), a dynamic and vibrant Community Based Organization nestled in the heart of Kasese Municipality, Western Uganda. Our organization is registered under the number CE/CBS/028/2023/2024, and our journey is driven by a powerful belief: that every individual, regardless of their abilities or disabilities, deserves the chance to live their dream life
</SubTitle>
<TopLine>Our Story</TopLine>
<SubTitle>Founded in 2019 by a passionate youth with a disability, VYD emerged from the realization that youth with disabilities were often overlooked, receiving minimal attention in both government and community-based projects. We started our journey in partnership with the Rwenzori Association of Parents of Children with Disabilities (RAPCD), which remains one of our key supporting organizations.

</SubTitle>
<TopLine>Our Focus</TopLine>
<SubTitle>At VYD, our core mission revolves around capacity building and economic empowerment for youth with disabilities. We recognize the need to break the cycle of financial dependency, and to achieve this, we primarily focus on nurturing entrepreneurship skills among our members.

</SubTitle>
<TopLine>Our Initiatives</TopLine>
<SubTitle>
<dl>
  <dt><strong>Savings and Credit Cooperative (SACCO):</strong></dt>
  <dd>We operate a SACCO that empowers our members to cultivate a savings culture and access loans, fostering financial independence</dd>
  <dt><strong>Education and Self-esteem:</strong></dt>
  <dd> We work to enhance the self-esteem of our youth members by helping them identify opportunities within their reach and understand their human rights as individuals with disabilities.</dd>
  <dt><strong>Access to Higher Education:</strong></dt>
  <dd>We believe in increasing access to quality higher education for our members, ensuring that they have equal opportunities to pursue their dreams.</dd>
  <dt><strong>Reproductive Health: </strong></dt>
  <dd>We promote reproductive health among our members, ensuring that they have access to essential information and services.</dd>
  <dt><strong>Vocational Training:</strong></dt>
  <dd> Our organization provides vocational training in various skills, including health, ICT, business, and leadership, enabling our youth to gain practical expertise.</dd>
  <dt><strong>Community Mobilization: </strong></dt>
  <dd>We engage in mobilization and sensitization efforts within the community, encouraging employers to recognize the potential of youth with disabilities.</dd>
  <dt><strong>Mindset Coaching and Mental Health: </strong></dt>
  <dd>We offer mindset coaching and raise awareness about mental health, addressing the unique challenges faced by our members.</dd>
  
</dl>
</SubTitle>
<TopLine></TopLine>
<SubTitle>At VYD, we align our efforts with the United Nations Convention on the Rights of Persons with Disabilities (UNCRPD). We are dedicated to breaking down barriers, promoting inclusion, and ensuring that the world recognizes the potential and contributions of youth with disabilities. Together, we are forging a brighter, more inclusive future. Join us in our journey of empowerment and change.</SubTitle>

                    </TextWrapper>
                </Column1>
                <Column2>

                <ImgWrapper>
                <img src="/images/about.jpg" alt=""/>
                </ImgWrapper>
                </Column2>
              
                </InfoRow>
             </InfoWrapper> 
             </InfoContainer>

        </>
    )
}

export default Infosection2
