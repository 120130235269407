import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Cards from '../Cards/Cards.jsx';
import Footer from '../Footer2'
import {Helmet} from 'react-helmet'

function Team() {
    return (
        <>
                <Helmet>
            <title>Meet the Team</title>
            <meta
      name="description"
      content="The Voice of The Youth with Disabilities (VYD) is a dynamic Community Based Organization in Uganda, passionately committed to empowering young people with disabilities. VYD's primary mission is to enhance capacity and foster economic self-sufficiency by nurturing entrepreneurship skills among the youth with disabilities. This approach aims to reduce their financial dependence on others and promote self-reliance within the community."
    />
    <link rel="apple-touch-icon" href="/images/ywd logo.png" />
    <style type="text/css"></style>

        </Helmet>
        <Cards />
        
        </>
    )
}


export default Team
