import React from 'react'
//import Cards from '../Cards2/CardUI';
import Infosection4 from '../InfoSection/Infosection4'
import Footer from '../Footer2'
import { PlanObjOne, PlanObjTwo, PlanObjThree, PlanObjFour} from './Data2'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'

function Plan() { 
    return (
        <>
                <Helmet>
            <title>Strategic Plan</title>
            <meta
      name="description"
      content="The Voice of The Youth with Disabilities (VYD) is a dynamic Community Based Organization in Uganda, passionately committed to empowering young people with disabilities. VYD's primary mission is to enhance capacity and foster economic self-sufficiency by nurturing entrepreneurship skills among the youth with disabilities. This approach aims to reduce their financial dependence on others and promote self-reliance within the community."
    />
    <link rel="apple-touch-icon" href="/images/ywd logo.png" />
    <style type="text/css"></style>

        </Helmet>
            <Component1>
             <h1>Summarized Strategic Plan</h1>
            </Component1>
            <Infosection4 {...PlanObjOne}/>
            <Infosection4 {...PlanObjTwo}/>
            <Infosection4 {...PlanObjThree}/>
            <Infosection4 {...PlanObjFour}/>
        </>
    )
}

export default Plan

const Component1 = styled.div` 
h1{
    font-size: 18px;
    color:black;
    text-align: center;
    justify-content:center;
    align-items:center;
  }
  `