import {React, useState,useEffect } from 'react'
import { IconContext } from 'react-icons/lib'
import { Nav, NavbarContainer, Navlogo, MobileIcon,Navicon, NavMenu, NavItem, NavLinks, } from './Navbar.elements'
import {FaBars, FaTimes} from 'react-icons/fa'
//import logo from '../images/ywd logo.png'

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] =useState(false)

     const changeNav = () => {
         if (window.scrollY  ) {
           setScrollNav(true);
         }   else{
            setScrollNav(false);
            }
         
     }
     useEffect(()=> {
         window.addEventListener('scroll', changeNav);
     }, [])

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (
        <>
        <IconContext.Provider value={{color:'black'}}>
         <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <Navlogo to = '/'>
                     <a href ='/'> 
                     <img src='/images/ywd logo.png' alt="/" width="56" height="55" /> 
                     </a>
                     </Navlogo>
         
                     <Navicon to = '/'>
                THE VOICE OF THE YOUTH WITH DISABILITIES
                </Navicon >
              
                <MobileIcon onClick={handleClick}> {click ? <FaTimes /> : <FaBars/>}
                     </MobileIcon>
                 <NavMenu onClick={handleClick} click={click}>
                         <NavItem>
                             <NavLinks to='/'>Home</NavLinks>
                         </NavItem>
                          <NavItem>
                             <NavLinks to='/about'>About us</NavLinks>
                         </NavItem>
                          <NavItem>
                             <NavLinks to='/team'>Members</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/strategic-plan'>Our Strategic Plan</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/partner-with-us'>Partner With Us</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/contact'>Contact Us</NavLinks>
                         </NavItem>
                     </NavMenu>
                </NavbarContainer> 
         </Nav>
         </IconContext.Provider>
        </>
    );
};

export default Navbar
