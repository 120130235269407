import React from 'react'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HerosSlider = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay:true,
      };
    
    return (
        <Carousel {...settings}>
            <Wrapper>
       <h3>The Voice of The Youth with Disabilities (VYD) is a dynamic Community Based Organization in Uganda, passionately committed to empowering young people with disabilities. VYD's primary mission is to enhance capacity and foster economic self-sufficiency by nurturing entrepreneurship skills among the youth with disabilities. This approach aims to reduce their financial dependence on others and promote self-reliance within the community</h3>
      </Wrapper>
     
        </Carousel>
    )
}

export default HerosSlider


const Carousel = styled(Slider)`
width: 100%;
ul li button {
&:before{
    font-size:10px;
    color: rgba(150, 150, 171)
}
}
li.slick-active button ::before {
color:black;
}
.slick-list {
    overflow: hidden;
}
button {
    z-index:1;
}

`
const Wrapper = styled.div`
object-fit:contain;
font-weight:bold;

h4 {
    
    font-size:85px;
    text-align: center;
    
@media screen and (max-width:768px){
font-size:0.95rem;
text-align:center;
align-items: center;
justify-content: center;
}
}
h3 {
    color: #000000;
    text-align: center;
    font-weight:900;
    font-size:0.95rem;

@media screen and (max-width:768px){
text-align:center;
align-items: center;
justify-content: center;
}
    
}

`

