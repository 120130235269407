import React from 'react';
import {InfosectionContainer, InfosectionWrapper, 
    InfoRow, 
    Column1, 
    TextWrapper,
     Heading, 
     SubTitle,
      Column2,
       ImgWrapper,
} from './Infosection5.elements'
const Infosection5=({
    imgStart,
      Headline,
          description,}) => {    return (
        <>
                   
         <InfosectionContainer>
             <InfosectionWrapper>
                 <InfoRow imgStart={imgStart}>
                 <Column1>
                 <TextWrapper>
                     <Heading><h2>{Headline}</h2></Heading>
                 </TextWrapper>
                 </Column1>
                 <Column2>
                 <ImgWrapper>
                 <SubTitle><h2>{description}</h2></SubTitle>
                        </ImgWrapper>
                 </Column2>
                 </InfoRow>
             </InfosectionWrapper>
             </InfosectionContainer>   

        </>
    )
}

export default Infosection5
