import React from 'react'
import styled from 'styled-components'
function Infosection6() {
    return (
          <InfoContainer>
              <h3>OUR PARTNERS</h3>
              <Content>
              <InfoWrapper>
                  <img src= "/images/RAPCD logo.jpg" href="www.rapcd.org" alt=""/>
              </InfoWrapper> 
               <InfoWrapper>
                  <img src= "/images/CDF logo.jpg" href="www.rapcd.org" alt=""/>
              </InfoWrapper>
              </Content>
              </InfoContainer>  
    )
}

export default Infosection6



const InfoContainer = styled.div`
margin-left: 100px;
h3{
font-size:20px;
color: darkblue;
}

@media screen and (max-width:768px){
    align-items:center;
    justify-content: center;
    margin-left:10px;
    h3{
        font-size:1rem;
    }
}
`
const Content = styled.div`
display:flex;
grid-gap:8px;
grid-template-columns: repeat(4,1fr);

@media screen and (max-width:768px){

}

`
const InfoWrapper = styled.div`
cursor: pointer;
overflow: hidden;
img{   
    width: 160px;
    height: 160px;
    object-fit: cover;
    @media screen and (max-width:768px){
width:60px;
height:70px;
}

}

&:hover {
    transform:scale(1.05);
    border-color: rgba(249, 249, 249, 0.8);
box-shadow: rgb(0 0 0/69%) 0px 26px 30px -10px rgba(0 0 0/72%) 0 16px 10px -10px;

}

`