import styled from "styled-components";


export const LoopVideo = styled.div`
//object-fit: cover;
width: 100%;
height: 100%;
position: absolute;
//z-index: -1;
top: 0;
bottom: 0;
right: 0;
left: 0;
overflow: hidden;
@media only screen and (max-width:768px){
    width: 100%;
    height: 100%;
}
`
export const HeroContainer = styled.div`
background: white;
//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 400px;
width: 100%;
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
/* margin-left: 60px;
margin-right: 60px;  
justify-content: center;
align-items:center; */
border: 6px solid transparent;
border-radius: 4px;
box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
object-fit: contain;
position: relative;

& :hover {

    
}


@media screen and (max-width:768px){
    margin-left: 0;
margin-right: 0;
width:100%;

    
}


`;

export const HeroContent = styled.div`
z-index: 3;
max-width:1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction:column;
align-items:center;

@media screen and (max-width:768px){
max-width: 90%;
}

/* :before {
    position: absolute;
color:yellow;
    font-size: 10px;
   position: center;  */
   
    
`;
export const VideoBg = styled.video`
width: 100%;      
height: 100%;
-o-object-fit: cover;
object-fit: cover;
//box-shadow: brown;
`
