import styled from "styled-components";
import background from '../white.jpg'

export const InfoContainer = styled.div`
color: #fff;
background: #fff;
max-height:100%;
z-index: 1;

@media screen and (max-width:768px) {
    padding: 100px 0; 
    display: flex;

}
`

export const InfoWrapper = styled.div`
display:grid;
z-index: 1;
height:100%;
width:100%;
max-width: 1100px;
margin-right:auto ;
margin-left:auto;
padding: 0 24px;
justify-content: center;
@media screen and (max-width:768px){
    height:100%
}
`
export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart})=>(imgStart ? `'col2 col1'` : `'col1 col2'`)} ;
@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart})=>(imgStart ? `'col2' 'col2'` : `'col1 col1' 'col2 col2'`)} ;

}
`

export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1; 

`

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`

export const TextWrapper = styled.div`
max-width:540px;
padding-top:0 ;
padding-bottom: 60px;

`
export const TopLine = styled.h4`
margin-bottom: 12px;
color: #FF1493;
font-size:16px;
line-height:16px;
font-weight:600;
letter-spacing:1.4px;
text-transform: uppercase;

`


export const Heading = styled.h1`
margin-bottom: 24px;
h1 {
font-size: 1rem;
line-height:1.1;
font-weight: 900;
color: black;
}
@media screen and  (max-width: 480px) {
 h1 {   font-size:1rem;
 }
}
`
export const SubTitle = styled.p`
max-width:440px;
margin-bottom: 35px;
font-size: 18px;
line-height:24px;
color: black;
`

export const ImgWrapper = styled.div`
max-width:555px;
height: 100%;
display: flex;
img {
width: 100%;
padding: 0 0 10px 0;
padding-right: 0;
}
@media screen and (max-width:768px) {
    display: grid;
}
`