import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { Container } from '../globalStyles' 

export const Nav = styled.nav`
background:${({scrollNav}) => (scrollNav ? 'rgba(0, 0, 0, 0.5)' : '#fff')};
height: 80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.2rem;
position: sticky;
top: 0;
z-index: 999;
//display: flex;
//background: rgba(0, 0, 0, 0.5);
@media screen and (max-width: 768px) {
width: 100%;
}
`; 

export const NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
height: 80px;
//z-index: 1;
//width: 100%;
//padding: 0 24px;
//max-width: 1100px;
${Container}


`; 

export const Navlogo = styled(Link)`
color: black;
justify-content: flex-start;
cursor: pointer;
text-decoration: none;
//font-size: 80rem;
display: flex;
align-items: center;
margin-left: 2rem;
@media screen and (max-width:768px){
    margin-left: 0px;
    font-size: 0.20rem;
    
    
}
`;

export const Navicon = styled(Link)`
  font-size: 0.80rem;
  flex-direction:column;
   display: grid;
   margin-left:1rem ;
   padding-bottom: 2px;
   padding-top: 22px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    
    @media only screen and (max-width:768px){
    font-size: 0.60rem;
    display: grid;
    //margin-left:3px ;
    
    }

    @media (max-width: 68px) {
        padding: 0.5px;
    }

`;

export const MobileIcon = styled.div`
display: none;
@media only screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
}
`;
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
//position: absolute;
text-align: center;
@media only screen and (max-width:960px) {
display: grid;
flex-direction: column;
width: 100%;
height: 550px;
position: absolute;
top: 60px;
left: ${({click}) => (click ? 0 :' -100%' )};
opacity: 1;
transition: all 0.5s ease;
background: white;
}
`;
export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
display: flex;
//position: relative;
//justify-content:end;
//align-items: center;
& hover {
    border-bottom: 2px solid #C71585;
}

@media  screen and (max-width: 768px){
    width: 100%;
& hover {
    border: none;
}
}
`;
export const NavLinks = styled(Link)`
  color: black;
    font-size: 0.89rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 0.88rem;
  height: 100%;
  margin-right: 30px;

& :hover {
    color: #C71585;
  border-bottom: 4px solid #C71585;
  transition: all 0.2s ease-out;
}


@media screen and (max-width: 960px){
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    & :hover {
        color: #C71585;
        transition: all 0.3s ease;
    }


}
`;
