export const PerObjOne ={
        topLine: 'Join Us in Empowering Youth with Disabilities',
    headLine: 'Partner with Us',
    Description: '',
    imgStart: false,
    


}

export const ObjOne ={
imgStart: false,
Headline:'VISION',
description:'VYD envisions creating a self-sustaining generation of youth with disabilities. We believe that with the right support and opportunities, our members can achieve independence and contribute meaningfully to society.',


}



export const ObjTwo ={
    imgStart: false,
    Headline:'MISSION',
    description:' Our mission is clear: we empower youth with disabilities to advocate for their rights and abilities, helping them identify social, political, and economic opportunities. We are committed to fostering an inclusive society where every voice is heard and every dream is attainable.',
    
    
    }


    export const Obj3 ={
        imgStart: false,
        Headline:'CORE OBJECTIVES',
        description:' 1. Collaborative Innovation, 2. Resource Mobilization, 3. Community Building, 4. Skill Empowerment ',
        
        
        }
        