import styled from "styled-components";

export const InfosectionContainer = styled.div`
color: #fff;
justify-content: center;
align-items: center;
max-height:100%;

@media screen and (max-width:768px) {
    display: grid;

}

`

export const TextWrapper = styled.div`
max-width:100%;
padding-top:2px ;
margin-left: 8px;

@media screen and  (max-width:768px) {
  margin-left:5px;
}

`
export const TopLine = styled.p`
h3{color: #FF1493;
font-size:15px;
line-height:16px;
font-weight:600;
letter-spacing:1.4px;
text-transform: uppercase;
}
@media screen and (max-width:768px) {
 h3{   font-size: 0.88rem;
 }
}
`


export const Heading = styled.h1`
h1{font-size: 14px;
line-height:2;
font-weight: 800;
color: black;
}
@media screen and  (max-width: 480px) {
 h1{   font-size:12px;
 }
}
`
export const SubTitle = styled.p`
h2{font-size: 13px;
font-weight: 600;
line-height:24px;
color: black;
}

@media screen and (max-width:768px) {
h2{  font-size:12px;
}
}
`

export const Desc1 = styled.ul`
font-size: 13px;
line-height:13px;
color: black;
  list-style-type: disc;
  margin: 0;
  overflow: hidden;
height:278px;
li {
  text-align:left;
  padding: 10px;
  overflow: hidden;
  display: block;
}
@media screen and (max-width:768px){
  height:100%;
  h5{
    font-size: 12px;
  }
}
`
export const Desc2 = styled.p`
font-size: 13px;
color: black;
  margin: 0;
  overflow: hidden;

li {
  list-style-type: disc;
  text-align:left;
  padding: 10px;
  display: inline-block;
  display: block;

  @media screen and (max-width:768px){
    padding: 12px;
      h5{
    font-size: 12px;
  }
}
  
}
`
