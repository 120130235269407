import React from 'react';
import styled from 'styled-components'
const NavReg = () => {
          
return (
        <>
         <Nav >
            <NavbarContainer>     
            <div style={{float:'right', position:'absolute', right:'0'}}>    
                <Right>Raliway Ward-Central Division, Kasese Municipality</Right>
                </div>
            <div style={{float:'left', position:'absolute', left:'0'}}>    
                <Left>Under Registration No. CE/CBS/028/2021/2022</Left>
                </div>
                </NavbarContainer> 
         </Nav>
        </>
    );
};

export default NavReg


const Nav = styled.nav`
background: #ffffff;
height: 20px;
display: flex;
top: 0;
//display: flex;
//background: rgba(0, 0, 0, 0.5);
@media screen and (max-width: 768px) {
display: none;
}
`; 

const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
//z-index: 1;
//width: 100%;
//padding: 0 24px;
//max-width: 1100px;
@media screen and (max-width: 768px) {
    display: none;
}
`; 

 const Right = styled.h4`
 float: right;
  color: black;
    font-size: 0.89rem;
  align-items: center;
  text-decoration: none;

`
const Left = styled.h4`
float: left;
color: black;
  font-size: 0.89rem;
align-items: center;
text-decoration: none;

`