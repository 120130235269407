import React, { useState } from "react";
import "./ContactsElements.css";
import { db } from "../firebase";
import {FaMapMarkerAlt, FaPhoneSquareAlt,FaMailBulk} from 'react-icons/fa'
import { Link } from "react-router-dom";
import Footer from './Footer2'
import { Helmet } from "react-helmet";






const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("contacts")
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        setLoader(false);
        alert("Your message has been submitted👍");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
  
    <>
            <Helmet>
            <title>Contact Us</title>
            <meta
      name="description"
      content="The Voice of The Youth with Disabilities (VYD) is a dynamic Community Based Organization in Uganda, passionately committed to empowering young people with disabilities. VYD's primary mission is to enhance capacity and foster economic self-sufficiency by nurturing entrepreneurship skills among the youth with disabilities. This approach aims to reduce their financial dependence on others and promote self-reliance within the community."
    />
    <link rel="apple-touch-icon" href="/images/ywd logo.png" />
    <style type="text/css"></style>

        </Helmet>
    <section class="contact">
      <div class="content">
      <h1>CONTACT US</h1>
      <p>We would love to hear from you</p>
      </div>
      <div class="container">
        <div class="contactInfo">
          <div class="box">
            <div class="icon"> <i><FaMapMarkerAlt /></i></div>
            <div class="text">
              <h3>Address</h3>
              <p>Raliway ward, <br />Kikonzo Cell, Central Division,Kasese Municipality</p>
            </div>
          </div>
          <div class="box">
            <div class="icon"><i><FaPhoneSquareAlt /></i></div>
            <div class="text">
              <h3>Tel Phone</h3>
              <p>+256-775-888-442 <br/>+256-789-618-464 (Whatsapp/SMS Only)</p>
            </div>
          </div>
          <div class="box">
            <div class="icon"> <i><FaMailBulk /></i></div>
            <div class="text">
              <h3>Email</h3>
               <p> <Link> vyduganda@gmail.com </Link></p> 
            </div>
          </div>
        </div>
      </div>
    </section>
    
    </>
  );
};

export default Contact;
