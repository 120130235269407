import styled from 'styled-components'


export const InfosectionContainer = styled.div`
height : 600px;
//width:  15px;
//width-left :200px;
display: flex;
flex-direction : column;
align-items: center;
justify-content: center;
//overflow : hidden;
//margin-left: 0; ;
//padding: 0px;
//margin: 0px;
//min-width: 980px;
@media screen and (max-width:768px){
    height: 850px;
}

`
export const InfosectionWrapper= styled.div`
//max-width: 0px;
//position: absolute;
//top: 0;
//width: 100%;
//height: 100%;
//overflow: hidden;
margin: 0 auto;
display: flex;
//width:100%;
grid-template-columns: 1fr 1fr 1fr 1fr;
align-items: center;
grid-gap: 10px;
padding: 0 50px ;
//column-width: px;
//--column-flex: 245;
justify-content: center;
//overflow: hidden;
margin-top: 10px;
a {
    text-decoration: none;
    color: black;
}
@media screen and (max-width:1024px) {
display: flex;
}
@media  screen and (max-width:768px) {
    margin-top:0px;
    display: grid;
    padding: 0 30px;
    grid-template-columns: 1fr 1fr;
    margin-right:0px;
    margin-left: 0px;
    
}

`
export const InfosectionCard = styled.div`
text-decoration: none;
text-decoration: none;
background: khaki;
align-items: center;
border-radius: 10px;
max-height:430px;
max-width: fit-content;
padding:  60px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;
bottom: 0px;
&:hover {
    transform:scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
}
 @media screen and (max-width:768px){
    max-height:400px;
    padding: 30px;
} 
`
export const InfoIcon = styled.div`
text-decoration: none;
img {
height: 160px;
width: 160px;
margin-bottom: 10px;
}
@media screen and (max-width:768px){
img {    height: 81px;
        width: 80px;
    //width: 1px;
    align-items: center;
    justify-content: center;
    //margin-bottom: 300px;
}
} 
`
export const InfoH2 = styled.h2`
//text-decoration: none;

//color: #fff;
//margin-bottom: 10px;
//justify-content: center;
h2{    
    text-align: center;
color:black;
font-size: 1rem;
font-weight: bold;
}
@media screen and (max-width:768px) {
    h2{
    font-size:0.93rem;
    }
}
`
export const InfoP = styled.p`
p{text-align: center;
font-size: 1rem;
text-decoration: none;}
@media screen and (max-width:768px) {
    p{font-size:0.80rem;
    }
}

`

export const InfosectionCard2 = styled.div`
text-decoration: none;
text-decoration: none;
background: #f6f0bc;
align-items: center;
border-radius: 10px;
max-height:420px;
max-width: fit-content;
padding:  60px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;
bottom: 0px;
&:hover {
    transform:scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
}
 @media screen and (max-width:768px){
    max-height:480px;
    padding: 30px;
} 
`