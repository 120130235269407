import React from 'react'
import Herosection from '../Herosection'
import Infosection from '../InfoSection/Infosection'
import Infosection5 from '../InfoSection/Infosection5'
import Infosection6 from '../InfoSection/Infosection6'
import Footer from '../Footer2'
import { ObjOne,ObjTwo, Obj3 } from './Data'
import HerosSlider from './Slider'


function Home() {
    return (
        <>
            <Herosection />
            <Infosection />
            <Infosection5 {...ObjOne}/>
            <Infosection5 {...ObjTwo}/>
            <Infosection5 {...Obj3}/>
            <HerosSlider />



        </>
    )
}


export default Home;