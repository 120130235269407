import React from 'react'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ceo from "../../assets/CDF logo.jpg"
import ceo2 from "../../assets/RAPCD logo.jpg"
import ceo3 from "../../assets/CDF logo.jpg"
import ceo1 from "../../assets/RAPCD logo.jpg"
const HerosSlider = () => {
    let settings = {
        draggable: true,
          autoplay: true, /* this is the new line */
          autoplaySpeed: 2000,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          touchThreshold: 1000,
      };
    
    return (
        <>
        <h3>Our Partners</h3>
        <Carousel {...settings}>

            <Wrapper>
            <Img src={ceo3} atl='' />
      </Wrapper>
      <Wrapper>
            <Img src={ceo2} atl='' />
      </Wrapper>
      <Wrapper>
            <Img src={ceo1} atl='' />
      </Wrapper>
      <Wrapper>
            <Img src={ceo} atl='' />
      </Wrapper>
      
        </Carousel>
        </>
    )
}

export default HerosSlider


const Carousel = styled(Slider)`
width: 100%;
ul li button {
&:before{
    font-size:10px;
    color: rgba(150, 150, 171)
}
}
li.slick-active button ::before {
color:black;
}
.slick-list {
    overflow: hidden;
}
button {
    z-index:1;
}

`
const Wrapper = styled.div`
display: inline-block;
justify-content: center;
align-items: center;
text-align: center;
@media screen and (max-width:768px){
}
}
h3 {
    font-size: 8px;
    text-align: center;

@media screen and (max-width:768px){
font-size:0.85rem;
text-align:center;
align-items: center;
justify-content: center;
}
    
}

`

const Img = styled.img`
    width:160px;
    height:160px
`

