import styled from "styled-components";

export const InfosectionContainer = styled.div`
color: #fff;
display: flex;
height: 260px;

@media screen and (max-width:768px) {
    display: flex;
    height:350px

}
`

export const InfosectionWrapper = styled.div`
display:grid;
z-index: 1;
height:11px;
width:100%;
//max-width: 1100px;
margin-right:auto ;
margin-left:auto;
padding: 0 24px;
justify-content: center;
margin-bottom: 0;
@media screen and  (max-width:768px) {
    height: 100%;
}

`
export const InfoRow = styled.div`
display: grid;
height:300px;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart})=>(imgStart ? `'col2 col1'` : `'col1 col2'`)} ;
@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart})=>(imgStart ? `'col2' 'col2'` : `'col1 col1' 'col2 col2'`)} ;

}
`

export const Column1 = styled.div`
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
height:5px;
margin-bottom: 0px;
padding: 0;
grid-area: col1; 

`

export const Column2 = styled.div`
box-sizing: border-box;
border-left: solid;
color: #F50057;
margin-bottom: 0px;
padding: 0 5px;
grid-area: col2;
@media screen and (max-width:768px){
padding: 0;
    border-top: solid;
border-left:none ;

}
`

export const TextWrapper = styled.div`
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
padding-top:0 ;
padding-bottom: 0;
@media screen and (max-width:768px) {
 
}

`


export const Heading = styled.h1`

h2{
font-size: 18px;
line-height:1.1;
font-weight: 600;
color: black;
}
@media screen and  (max-width: 480px) {
h2{    font-size:1rem;
}
}
`
export const SubTitle = styled.p`
display:flex;
//margin-bottom: 85px;

h2 {
font-size: 18px;
line-height:24px;
color: black;
}
@media screen and (max-width:768px) {
    h2{ 
    font-size:0.8rem;
}
}
`

export const ImgWrapper = styled.div`
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
max-width:450px;
height: 150px;
display: flex;
img {
width: 100%;
padding: 0 0 10px 0;
padding-right: 0;
}
@media screen and (max-width:768px) {
    max-width:200px;
    height:250px;
}
`