export const PlanObjOne = {
    imgStart:false,
    topLine:'1: AWARENESS',
    id: 'about',
    Headline:'VYD"s overarching mission revolves around championing the rights of individuals living with disabilities, while also actively promoting the broader principles of inclusivity, active participation, and accessible education.',
    description:'To foster the active engagement and integration of young individuals with disabilities in the process of development.',
    alt:'kihembo',
    p1:'Sharing information about young people living with disabilities with the public.',
    p2:'Achieving a substantial increase in the inclusion and active participation of youth with disabilities in socio-economic development programs.',
    p3:'By the year 2027, our goal is for 75% of youth with disabilities to have a comprehensive understanding of their fundamental human rights.',
    p4:'We aim for the concerns and needs of youth with disabilities to be prominently incorporated into a minimum of 50% of sector plans, national policies, and budget allocations, specifically in areas such as Health, Education, Finance, and Planning.',   
    pp1:'Collaborating with various organizations to collectively tackle the challenges impacting youth with disabilities at both the district and national levels.' ,
    pp2:'Taking a co-leadership role in organizing an annual district event focused on human rights, providing a platform for individuals with disabilities to lead discussions on contemporary disability-related topics.',
    pp3:'Establishing a team of civic educators within member districts to raise awareness regarding the skill requirements of youth with disabilities in relation to grassroots leadership and electoral procedures. Additionally, this team will work towards advancing inclusion in the electoral process itself.',
    pp4:'Assisting other Disabled Peoples Organizations (DPOs) in taking the lead and actively participating in diverse debates, including those held in parliament and at the local government level.',
    pp6:'Providing training sessions for members and caregivers to educate them about their rights and the appropriate channels for reporting.',
    pp7:'Conduct advocacy and awareness workshops to empower individuals with cerebral palsy with knowledge about their economic and leadership rights across all districts in Western Uganda.',
    pp8:'Collaborating with other associations to access government and development partner support for economic empowerment programs.',
    
    }





    export const PlanObjTwo = {
        imgStart:true,
        topLine:'2: ECONOMIC EMPOWERMENT OF YOUTH WITH DISABILITIES',
        id: 'two',
        Headline:'Elevating youth with disabilities living standards necessitates economic empowerment, accessible education, healthcare, advocacy, and inclusive policies to foster their full participation and inclusion in society.',
        description:'Transforming community perceptions of YWDs involves challenging stereotypes, fostering empathy, and highlighting their abilities to promote inclusivity and acceptance.',
        alt:'kihembo',
        p1:'Expanding the financial inclusion of YWDs involves facilitating their access to mainstream financial institutions, promoting economic independence and self-sufficiency.',
        p2:'Creating diverse avenues for personal income generation, empowering individuals with financial autonomy and reducing dependence on external support.',
        p3:'A rising number of Youth with Disabilities (YWDs) actively participating in various Social Collective Organizations (SOCCOs), fostering inclusion and collaboration.',
        p4:'Enhancing financial knowledge and skills among Youth with Disabilities (YWDs), promoting their economic independence and responsible money management.',
        p6:'Elevating the financial earnings of young individuals with disabilities, empowering them to achieve greater economic stability and independence. ',
           
        pp1: 'Initiating collaborative and individual projects to promote entrepreneurship, economic growth, and self-sufficiency within the youth with disabilities community.' ,
        pp2:'Creating partnerships with microfinance institutions to incorporate disability-inclusive practices into their operations, ensuring financial services accessibility for all.',
        pp3:'Assisting members in accessing savings and credit schemes, empowering them to improve their living standards and achieve financial independence. ',
        pp4:'Participating in a centralized database of Persons with Disabilities (PWDs) engaged in income-generating initiatives, facilitating better coordination and support.',
        pp5:'Providing member training in financial management and strategic planning, equipping them with skills for effective financial control and future planning.',
        pp6:'Engaging with financial institutions to explore methods for YWDs inclusion and augmenting their loan limits, fostering economic empowerment. ',
        pp7:'Promoting and motivating YWDs to submit applications for government economic empowerment grants, fostering financial self-sufficiency and independence.   ',
        pp8:'Delivering agricultural training to both YWDs and their families, empowering them with knowledge and skills in farming and food production.',
        pp9:'Providing support and motivation to youth with disabilities (YWDs) to cultivate food crops like maize, beans, and cassava, both for income generation and dietary improvement.',
        pp10:'Establish commercial units serving as dedicated learning and training centers for youth with disabilities, fostering skill development and economic empowerment.',
        pp11:'Stimulating entrepreneurial innovation and engaging youth with disabilities in commercial ventures to prevent redundancy and promote economic sustainability.'
        }





        export const PlanObjThree = {
            imgStart:false,
            topLine:'3: EDUCATION',
            id: 'education',
            Headline:'Guaranteeing the entitlement to inclusive, high-quality education for youth with disabilities.',
            description:'Facilitating the integration of Youth with Disabilities (YWDs) into both formal and informal education settings to ensure their inclusive participation.',
            alt:'kihembo',
            p1: 'Achieving an 80% active participation rate of Youth with Disabilities (YWDs) in community-level functional literacy programs.',
            p2:'Half of all educational institutions offering Special Needs Education (SNE), resulting in an increased enrollment of YWDs across all education levels.',
            p3:'Elevated participation rates of Youth with Disabilities (YWDs) in basic skills development programs.',
            p4:'A substantial increase in the number of Youth with Disabilities (YWDs) benefiting from affirmative action initiatives across all educational stages.',
            p6:'A rise in the enrollment of children with disabilities at both primary and secondary education levels.',
            p7:'Higher retention and completion rates of individuals with disabilities in the basic education cycle.',
    
    
            pp1:'Enhancing our collaboration with both special needs and mainstream schools to decrease tuition costs for students with disabilities and secure scholarships.' ,
            pp2:'Advocate for and mobilize 80% of Youth with Disabilities (YWDs) to engage actively in community-level functional literacy programs.',
            pp3:'Advance Special Needs Education (SNE) training and implementation in primary and secondary schools via stakeholder workshops and collaborations.',
            pp4:'Engage in negotiations to secure certification recognition for Youth with Disabilities (YWDs) completing basic skills training programs, enhancing their employability.',
            pp5:'Campaign for the inclusion of Youth with Disabilities (YWDs) in government committees focused on mainstreaming disability in education and employment.',
            pp6:'Champion the adjustment of eligibility criteria within the education loan scheme to better accommodate the needs of Youth with Disabilities (YWDs).',
            pp7:'Lead a campaign to encourage the retention of students with disabilities, involving school managers, parents, and pupils, and promote their active engagement in both academic and extracurricular activities.',
            pp8:'Develop programs and educational curricula specifically tailored for individuals with disabilities who are currently out of school.',
            }







export const PlanObjFour = {
imgStart:true,
topLine:'4: HEALTH',
id: 'about',
Headline:'Achieve the entitlement to high-quality healthcare services for Youth with Disabilities (YWDs).',
description:'Enhance the physical and mental well-being of Youth with Disabilities (YWDs).',
alt:'kihembo',
p1:'Percentage of sexual and reproductive health (SRH) service providers offering disability-friendly services to cater to diverse needs effectively.',
p2:' Expand the availability of mental health and counseling services, ensuring increased accessibility for Youth with Disabilities (YWDs). ',
p3:'Healthcare professionals actively identifying individuals with disabilities, with a special emphasis on attentive recognition and support for their unique needs. ',
p4:'Enhancing the physical well-being of Youth with Disabilities (YWDs) both within their homes and in public spaces to promote overall health. ',
p7:'Cost-effective rehabilitation programs for children with disabilities, ensuring affordable access to essential support and services for their well-being. ',
p9:'Ensuring Youth with Disabilities (YWDs) possess a thorough understanding of HIV/AIDS, promoting awareness and prevention within this demographic.',


pp1: 'Forming collaborations with rehabilitation and therapy centers to provide essential support and services for individuals with disabilities.  ',
pp2:'Take the initiative in creating networks among key stakeholders from diverse sectors, including law enforcement, healthcare, advocacy, and rehabilitation services. These networks will support a comprehensive approach to sexual and reproductive health (SRH) and HIV/AIDS programs tailored to the needs of Youth with Disabilities (YWDs).',
pp3:'Mental health education and training, fostering awareness and equipping YWDs with essential knowledge and skills for mental well-being. ',
pp4:'Organizing training sessions for healthcare associations and providers to educate them about health rights, particularly in the context of sexual and reproductive health (SRH) and HIV/AIDS support.',
pp5:'Create connections with schools and higher education institutions to promote the health and well-being of students with disabilities.',
pp6:'Execute comprehensive health education initiatives aimed at enhancing the retention of Youth with Disabilities (YWDs) in schools and fostering role models for educating Persons with Disabilities (PWDs) on matters related to sexual and reproductive health (SRH) and HIV/AIDS.',

}