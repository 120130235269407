import React from 'react'
import {LoopVideo, HeroContainer, VideoBg, HeroContent} from './Herosection.elements'
//import {Video} from '../../videos/tree loop.mp4'
import HerosSlider from './HerosSlider'
function Herosection() {
    return (
        <HeroContainer>
        <LoopVideo>
            <VideoBg autoPlay loop muted src='/videos/tree loop.mp4' type= 'video/mp4' />
        </LoopVideo>
        <HeroContent>
        <HerosSlider />
        </HeroContent>
        </HeroContainer>
    )
}

export default Herosection
