import React from 'react';
import {InfosectionContainer, 
    TextWrapper,
     TopLine, 
     Heading, 
     SubTitle,
    Desc1,
Desc2,
} from './Infosection4.elements'
const Infosection4=({
    imgStart,
     topLine,
     imgscr,   
      Headline,
      alt,
          description,
          p1,
          p2,
      p3,
      p4,
      p5,
      p6,
      p7,
      p8,
      p9,
      p10,
      p11,
      p12,   
      pp1,
      pp2,
  pp3,
  pp4,
  pp5,
  pp6,
  pp7,
  pp8,
  pp9,
  pp10,
  pp11,
  pp12,}) => {    return (
        <>
                   
         <InfosectionContainer>
                 <TextWrapper>
                     <TopLine><h3>STRATEGIC FOCUS AREA{topLine}</h3></TopLine>
                     <Heading><h1>Purpose: {Headline}</h1></Heading>
                     <SubTitle><h2>Strategic Objective: {description}</h2></SubTitle>
                     <Desc1>
                         <div>
                        <h5>Targeted Outcomes</h5>      
                     <ul type='disc'>
                            <li>{p1}</li>
                            <li>{p2}</li>
                            <li>{p3}</li>
                            <li>{p4}</li>
                            <li>{p6}</li>
                            <li>{p7}</li>
                            <li>{p8}</li>
                            <li>{p9}</li>
                            <li>{p10}</li>
                            <li>{p11}</li>
                            <li>{p12}</li>

                            </ul>
                            </div>
                          </Desc1>
                         
                          <Desc2>
                        <h5>Activities</h5>      
                     <ul >
                            <li>{pp1}</li>
                            <li>{pp2}</li>
                            <li>{pp3}</li>
                            <li>{pp4}</li>
                            <li>{pp6}</li>
                            <li>{pp7}</li>
                            <li>{pp8}</li>
                            <li>{pp9}</li>
                            <li>{pp10}</li>
                            <li>{pp11}</li>
                            <li>{pp12}</li>

                            </ul>
                          </Desc2>

                 </TextWrapper>
             </InfosectionContainer>   

        </>
    )
}

export default Infosection4
