import Container from "react-bootstrap/Container";

function Footer2() {
    return (
        <footer>
            <hr/>
            <Container>
                <div className="row">
                    <div className="col-6 col-md-2 mb-3">
                        <h5> Links</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/about"
                                                             className="nav-link p-0 text-body-secondary">About</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/team"
                                                             className="nav-link p-0 text-body-secondary">Members</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/strategic-plan"
                                                             className="nav-link p-0 text-body-secondary">Our Strtegic Plan</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/partner-with-us"
                                                             className="nav-link p-0 text-body-secondary">Partnership</a>
                            </li>
                            <li className="nav-item mb-2"><a href="/contact"
                                                             className="nav-link p-0 text-body-secondary">Contact Us</a>
                            </li>
                        </ul>
                    </div>

             
                    <div className="col-md-5 offset-md-1 mb-3">
                        <form>
                            <h5>Send us an email</h5>
                            <p>vyduganda@gmail.com</p>
                        </form>
                    </div>
                </div>
            </Container>
            <div className="bg-dark text-white">
                <Container>
                    <div className="d-flex flex-column flex-sm-row justify-content-between border-top py-2">
                        <p className={"mb-0"}>&copy; 2023 VYD. All rights reserved.</p>
                        <ul className="list-unstyled d-flex mb-0">
                            <li className="ms-3"><a className="link-body-emphasis" href="#link">
                                <i className="bi bi-twitter"></i>
                            </a></li>
                            <li className="ms-3"><a className="link-body-emphasis" href="#link">
                                <i className="bi bi-instagram"></i>
                            </a></li>
                            <li className="ms-3"><a className="link-body-emphasis" href="#link">
                                <i className="bi bi-facebook"></i>

                            </a></li>
                        </ul>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default Footer2;